import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/TalAKtmVzP0">
    <SEO title="2021 Vision - Vision Sunday" />
  </Layout>
)

export default SermonPost
